<template>
  <div style="margin: -8px;">
    <div style="background: rgba(239, 239, 239,0.5);
    padding: 1px 10px 10px 10px;">
      <h1 style="text-align:center;margin-bottom:0" class="new-h1">
        <span class="title">联系我们</span>
      </h1>
      <el-row :gutter="10"
              style="font-size: 18px; text-align: center">
        <!-- <el-col :xs="0" :sm="0" :md="4" :lg="4" :xl="4"></el-col> -->
        <el-col :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
          <div>
            <i class="el-icon-map-location"></i>
            <p class="smallp">地址</p>
            <p>广州市黄埔区科学道112号绿地中央广场B2 809室</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
          <div>
            <i class="el-icon-phone-outline"></i>
            <p class="smallp">业务热线</p>
            <p>13242747939</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
          <div>
            <i class="el-icon-chat-line-square"></i>
            <p class="smallp">微信号</p>
            <p>13242747939</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
          <div>
            <i class="el-icon-service"></i>
            <p class="smallp">QQ咨询</p>
            <p>56187341</p>
          </div>
        </el-col>
        <!-- <el-col :xs="0" :sm="0" :md="4" :lg="4" :xl="4"></el-col> -->
      </el-row>
    </div>
    <div class="change"
         style="
        background: #ff6537;
        color: rgb(255, 255, 255);
        font-size: 16px;
        width: 100%;
      ">
      <el-row style="width：100%">
        <el-col :xs="15"
                :sm="15"
                :md="5"
                :lg="5"
                :xl="5">
          <h2>解决方案</h2>
          <el-menu :default-active="activeIndex"
                   @select="handleSelect"
                   router>
            <el-menu-item index="/solution/one">
              云巡更解决方案
            </el-menu-item>
            <el-menu-item index="/solution/two">
              园区/校企安保巡更应用方案
            </el-menu-item>
            <el-menu-item index="/solution/three">
              智能电网GPS巡更应用方案
            </el-menu-item>
            <el-menu-item index="/solution/four">
              自来水厂智能巡更应用方案
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :xs="12"
                :sm="12"
                :md="4"
                :lg="4"
                :xl="4">
          <h2>产品中心</h2>
          <el-menu :default-active="activeIndex"
                   @select="handleSelect"
                   router>
            <el-menu-item index="/product/one">
              USB巡更棒
            </el-menu-item>
            <el-menu-item index="/product/two">
              4G巡更棒
            </el-menu-item>
            <!-- <el-menu-item index="/product/three">
              巡更点
            </el-menu-item> -->
          </el-menu>
        </el-col>
        <el-col :xs="12"
                :sm="12"
                :md="4"
                :lg="4"
                :xl="4">
          <h2>关于我们</h2>
          <el-menu :default-active="activeIndex"
                   @select="handleSelect"
                   router>
            <el-menu-item index="/about">
              公司简介
            </el-menu-item>
            <el-menu-item index="/about">
              加入我们
            </el-menu-item>
            <el-menu-item index="/about">
              联系方式
            </el-menu-item>
          </el-menu>
        </el-col>
                <el-col :xs="12"
                :sm="12"
                :md="4"
                :lg="4"
                :xl="4">
          <div>
            <h2>联系我们</h2>
            <el-menu>
              <el-menu-item>QQ 56187341</el-menu-item>
              <el-menu-item>电话 13242747939</el-menu-item>
              <el-menu-item>邮箱 56187341@qq.com</el-menu-item>
            </el-menu>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24"
                :lg="5"
                :xl="5">
          <div class="grid-content bg-purple-light">
            <h2>关注我们 <i style="color:white;font-size:24px"
                 class="el-icon-circle-check"></i> </h2>
            <el-menu>              
              <img src="@/assets/wechat.jpg"
                  style="margin-left:20px;height:120px"
                     alt="">
              <el-menu-item>关注我们，了解更多资讯</el-menu-item>
            </el-menu>
          </div>
        </el-col>
      </el-row>    
      <el-divider></el-divider>
    <div style="text-align:center;height: 50px;">
      版权所有 ©2018 广州智享云联科技有限公司 <a style="color:white" href="http://icp.chinaz.com/">粤ICP备18094680号-2</a> 
    </div>
    </div>

    <!-- <div style="
        background: #ff6537;
        color: rgb(255, 255, 255);
        font-size: 16px;
        width: 100%;
      ">
     
      <el-row :gutter="10"
              style="margin:0 10px">
        <el-col :xs="12"
                :sm="12"
                :md="4"
                :lg="4"
                :xl="4">
          <div class="grid-content bg-purple">
            <h2>关于我们</h2>
            <ul>
              <router-link :to="{name: 'AboutView'}">
                <li>公司简介</li>
              </router-link>
              <router-link :to="{name: 'AboutView'}">
                <li>加入我们</li>
              </router-link>
              <router-link :to="{name: 'AboutView'}">
                <li>联系方式</li>
              </router-link>
            </ul>
          </div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24"
                :lg="5"
                :xl="5">
          <div class="grid-content bg-purple-light">
            <h2>关注我们 <i style="color:white;font-size:24px"
                 class="el-icon-circle-check"></i> </h2>
            <ul>
              <li><img src="@/assets/wechat.jpg"
                     width="120px"
                     alt=""></li>
              <li>关注我们，了解更多资讯</li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeIndex: this.$route.path
    }
  },
  // 通过监听器解决  导航菜单在页面刷新时会回到默认项
  watch: {
    '$route' (to) {
      // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
      this.activeIndex = to.path
    }
  },
  methods: {
    handleSelect (key) {
      // console.log(key, "看看路径");
      this.activeIndex = key
      window.scrollTo(0, 0)
    }
  },
}
</script>
<style lang="less" scoped>
ul {
  // margin-left:20px ;
  min-height: 200px;
  a {
    text-decoration: none;
    color: #fff;
  }
  li {
    list-style: none;
  }
}
div {
  h2 {
    // text-align: center;
    margin-left: 20px;
    font-weight: 500;
  }
}
.el-row {
  // display: flex;
  .el-col {
    min-height: 150px;
    div {
      i {
        color: #ff6537;
        font-size: 45px;
        margin: 5px 0;
      }
      p {
        line-height: 30px;
        margin: 0;
      }
      .smallp {
        font-size: 14px;
        color: #666;
        line-height: 25px;
      }
    }
  }
}
</style>
<style lang="less">
.change {
  height: auto;
  // margin: -8.4px;
  .el-row {
    .el-col {
      .el-menu {
        background: #ff6537;
        color: white;
        border-right: solid 1px #e6e6e600;
        .el-menu-item {
          background: #ff6537;
          font-size: 18px;
          color: white;
        }
      }
    }
  }
}
</style>