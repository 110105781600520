import { render, staticRenderFns } from "./AboutView.vue?vue&type=template&id=52d57277&scoped=true&"
import script from "./AboutView.vue?vue&type=script&lang=js&"
export * from "./AboutView.vue?vue&type=script&lang=js&"
import style1 from "./AboutView.vue?vue&type=style&index=1&id=52d57277&prod&lang=less&scoped=true&"
import style2 from "./AboutView.vue?vue&type=style&index=2&id=52d57277&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d57277",
  null
  
)

export default component.exports